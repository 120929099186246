<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <b-img
        fluid
        :src="pageData.favicon || pageData.logo"
        alt="Fems"
        :style="{ height: '30px' }"
      />
      <h2
        class="brand-text text-primary ml-1"
        :style="{ lineHeight: '30px' }"
      >
        {{ pageData.slug.toUpperCase() }}
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Impersonating
        </h2>
        <p class="mb-2">
          Please Wait
        </p>
        <preloader v-if="isLoading" />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BImg } from 'bootstrap-vue'
// import VuexyLogo from '@core/layouts/components/Logo.vue'
import store from '@/store/index'

import LocalStorageService from '@/@service/utils/LocalStorage.service'
import AuthService from '@/@service/api/Auth.service'
import router from '@/router'
import { userAbility } from '@/@service/utils/utils'
import Preloader from '../components/preloader/preloader.vue'

export default {
  components: {
    // VuexyLogo,
    BLink,
    BImg,
    Preloader,
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/error.svg'),
      getPageData: JSON.parse(LocalStorageService.get('pageData')) || '',
      pageData: {},
      isLoading: true,
    }
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.downImg = require('@/assets/images/pages/error-dark.svg')
        return this.downImg
      }
      return this.downImg
    },
  },
  created() {
    if (this.getPageData) {
      this.pageData = this.getPageData
    } else {
      this.onLogout()
    }
    const { accessToken } = router.currentRoute.params
    LocalStorageService.set('accessToken', accessToken)
    if (accessToken) {
      this.isLoading = true
      this.onRefreshToken()
      return
    }
    this.onLogout()
  },
  methods: {
    onRefreshToken() {
      AuthService.refreshTokenGenerate()
        .then(response => {
          const res = response.data
          if (res.status) {
            LocalStorageService.set('refreshToken', res.refresh_token)
            this.userDataMapulation()
          } else {
            this.onLogout()
          }
        })
        .catch(() => {
          this.onLogout()
        })
    },

    userDataMapulation() {
      AuthService.userInfo()
        .then(resp => {
          const response = resp.data
          if (response.status) {
            const res = response.data
            let userInfo = {}
            let isAdmin = ''
            if (res?.employee_information) {
              userInfo = res.employee_information
              isAdmin = res.employee_information.is_admin
            } else if (res?.guardian_information) {
              userInfo = res.guardian_information
            } else if (res?.student_information) {
              userInfo = res?.student_information
            } else if (res?.alumni_information) {
              userInfo = res?.alumni_information
            }

            userInfo.ability = userAbility(isAdmin)

            if (
              res?.employee_information
              && [2001140000, 2001150001, 2001150051, 2001200021].includes(
                userInfo.employee_id,
              )
            ) {
              userInfo.ability.push({
                action: 'customResource',
                subject: 'customResource',
              })
            }
            if (
              res?.employee_information
              && [2002180072].includes(userInfo.employee_id)
            ) {
              const acl = [
                {
                  action: 'accounting',
                  subject: 'accounting',
                },
                {
                  action: 'payment-collection',
                  subject: 'payment-collection',
                },
                {
                  action: 'student-concessions',
                  subject: 'student-concessions',
                },
                {
                  action: 'vouchers',
                  subject: 'vouchers',
                },
                {
                  action: 'fintech',
                  subject: 'fintech',
                },
                {
                  action: 'payment-cycle-config',
                  subject: 'payment-cycle-config',
                },
                {
                  action: 'group-invoice',
                  subject: 'group-invoice',
                },
                {
                  action: 'due-report',
                  subject: 'due-report',
                },
                {
                  action: 'student-ledger',
                  subject: 'student-ledger',
                },
              ]

              userInfo.ability.push(...acl)
            }

            userInfo.role = 'admin'
            LocalStorageService.set('userData', JSON.stringify(userInfo))
            LocalStorageService.set('pageData', JSON.stringify(this.pageData))
            this.$ability.update(userInfo?.ability)
            // window.location.href = `${process.env.VUE_APP_CORE_BASE_URL}#dashboard`
            window.location.href = `${
              process.env.VUE_APP_CORE_BASE_URL
            }login/fems_admin_reset_token/?t=${LocalStorageService.get(
              'accessToken',
            )}`
          } else {
            this.onLogout()
          }
          this.isLoading = false
        })
        .catch(() => {
          this.onLogout()
        })
    },

    // eslint-disable-next-line class-methods-use-this
    onLogout() {
      LocalStorageService.clear()
      window.location.href = `${process.env.VUE_APP_CORE_BASE_URL}login/logout_alt`
      // window.location.reload()
      this.$router.push({ name: 'auth-login' })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
</style>
